import React, { Fragment, memo, useCallback } from 'react';

import { FileUrl, ClassName, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';

import { ProjectNanoID } from '../../../projectsTypes';

import {
  CreateMessageWithProjectNanoIdQueryResponse,
  CREATE_MESSAGE_WITH_PROJECT_NANO_ID
} from '../../../../messages/queries/createMessageWithProjectNanoId.query';

import { useCreateMessageWithProjectNanoId } from '../../../../messages/hooks/useCreateMessageWithProjectNanoId';

import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';
import {
  useImageEditor,
  ImageEditor,
  ImageEditorOnSubmit
} from '../../../../../helpers/ImageEditor';

import { Files } from '../../../../../utils/Files';

interface EditImageAndSendToProjectModalButtonProps {
  projectNanoId: ProjectNanoID;
  file: FileUrl;
  className?: ClassName;
  i18nText?: I18nText;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  toggleImageEditorBackdrop?: () => void;
}

function EditImageAndSendToProjectModalButton({
  projectNanoId,
  file,
  className,
  i18nText,
  icon,
  iconClassName,
  toggleImageEditorBackdrop
}: EditImageAndSendToProjectModalButtonProps) {
  const { enabled, hideEditor, toggleEnabled } = useImageEditor({
    toggleBackdrop: toggleImageEditorBackdrop
  });

  const { createMessageWithProjectNanoId } =
    useCreateMessageWithProjectNanoId<CreateMessageWithProjectNanoIdQueryResponse>(
      {
        projectNanoId,
        query: CREATE_MESSAGE_WITH_PROJECT_NANO_ID
      }
    );

  const handleSubmit = useCallback<ImageEditorOnSubmit>(
    (data) => {
      return createMessageWithProjectNanoId({
        ...data,
        projectNanoId
      });
    },
    [createMessageWithProjectNanoId, projectNanoId]
  );

  if (!Files.isImage(file)) {
    return null;
  }

  return (
    <Fragment>
      <PureIconButtonHelper
        className={className}
        i18nText={i18nText}
        iconClassName={iconClassName}
        icon={icon}
        onClick={toggleEnabled}
      />
      <ImageEditor
        enabled={enabled}
        image={file}
        hideEditor={hideEditor}
        onSubmit={handleSubmit}
        uploadFileType="fileAttachment"
      />
    </Fragment>
  );
}

export default memo<EditImageAndSendToProjectModalButtonProps>(
  EditImageAndSendToProjectModalButton
);
