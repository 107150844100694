import {
  RemoveFileAttachmentCacheKeys,
  RemoveFileAttachmentGqlQuery,
  RemoveFileAttachmentGqlStatus,
  FileAttachmentGqlError,
  FileAttachmentUUID
} from '../../fileAttachmentsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface RemoveFileAttachmentInput {
  uuid: FileAttachmentUUID;
}

interface RemoveFileAttachmentResponse {
  removeFileAttachment: {
    status: RemoveFileAttachmentGqlStatus;
    errors: RemoveFileAttachmentError;
  };
}

interface RemoveFileAttachmentError {
  fullMessages: FileAttachmentGqlError;
}

interface RemoveFileAttachmentOptions {
  cacheKeys: RemoveFileAttachmentCacheKeys;
  query: RemoveFileAttachmentGqlQuery;
}

const action = 'removeFileAttachment';

function useRemoveFileAttachment({
  query,
  cacheKeys
}: RemoveFileAttachmentOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    RemoveFileAttachmentInput,
    RemoveFileAttachmentResponse,
    RemoveFileAttachmentError,
    unknown
  >({ action, cacheKeys, query });

  return {
    removeFileAttachmentData: updateQueryData,
    removeFileAttachmentError: updateQueryError,
    removeFileAttachmentLoading: updateQueryLoading,
    removeFileAttachmentErrorMessage: updateQueryErrorMessage,
    removeFileAttachment: updateQuery,
    removeFileAttachmentReset: updateQueryReset
  };
}

export default useRemoveFileAttachment;
