import React, { useCallback } from 'react';

import { IconsEnum } from '../../../../assets/icons/types';
import { SourceFileUUID } from '../../sourceFilesTypes';
import { ClassName, I18nText } from '../../../../types';

import { DELETE_SOURCE_FILE_QUERY } from '../../queries/deleteSourceFile.query';

import { useDeleteSourceFile } from '../../hooks/useDeleteSourceFile';
import { useShowToastOnErrorChange } from '../../../../common/hooks/useShowToastOnErrorChange';

import { PureIconButtonHelper } from '../../../../helpers/buttons/PureIconButtonHelper';

interface DeleteSourceFileButtonProps {
  cacheKeys?: string[];
  className?: ClassName;
  i18nText?: I18nText;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  sourceFileUuid: SourceFileUUID;
}

function DeleteSourceFileButton({
  cacheKeys,
  className,
  i18nText,
  icon,
  iconClassName,
  sourceFileUuid
}: DeleteSourceFileButtonProps) {
  const {
    deleteSourceFile,
    deleteSourceFileLoading,
    deleteSourceFileErrorMessage
  } = useDeleteSourceFile({
    cacheKeys: cacheKeys,
    query: DELETE_SOURCE_FILE_QUERY
  });

  const handleDeleteSelectedLifestyle = useCallback(
    () =>
      deleteSourceFile({ uuid: sourceFileUuid }).catch((error) =>
        console.log(error)
      ),
    [deleteSourceFile, sourceFileUuid]
  );

  useShowToastOnErrorChange({ error: deleteSourceFileErrorMessage });

  return (
    <PureIconButtonHelper
      className={className}
      disabled={deleteSourceFileLoading}
      i18nText={i18nText}
      icon={icon}
      iconClassName={iconClassName}
      onClick={handleDeleteSelectedLifestyle}
    />
  );
}

export default DeleteSourceFileButton;
