import {
  DeleteSelectedProductCacheKeys,
  DeleteSelectedProductGqlQuery,
  DeleteSelectedProductGqlStatus,
  SelectedProductGqlError,
  SelectedProductUUID
} from '../../selectedProductsTypes';

import { useDeleteQuery } from '../../../common/hooks/base/reactQuery/useDeleteQuery';

interface DeleteSelectedProductInput {
  uuid: SelectedProductUUID;
}

interface DeleteSelectedProductResponse {
  deleteSelectedProduct: {
    status: DeleteSelectedProductGqlStatus;
    errors: DeleteSelectedProductError;
  };
}

interface DeleteSelectedProductError {
  fullMessages: SelectedProductGqlError;
}

interface DeleteSelectedProductOptions {
  cacheKeys: DeleteSelectedProductCacheKeys;
  query: DeleteSelectedProductGqlQuery;
}

const action = 'deleteSelectedProduct';

function useDeleteSelectedProduct({
  query,
  cacheKeys
}: DeleteSelectedProductOptions) {
  const {
    deleteQueryData,
    deleteQuery,
    deleteQueryReset,
    deleteQueryError,
    deleteQueryLoading,
    deleteQueryErrorMessage
  } = useDeleteQuery<
    DeleteSelectedProductInput,
    DeleteSelectedProductResponse,
    DeleteSelectedProductError
  >({ action, cacheKeys, query });

  return {
    deleteSelectedProductData: deleteQueryData,
    deleteSelectedProductError: deleteQueryError,
    deleteSelectedProductLoading: deleteQueryLoading,
    deleteSelectedProductErrorMessage: deleteQueryErrorMessage,
    deleteSelectedProduct: deleteQuery,
    deleteSelectedProductReset: deleteQueryReset
  };
}

export default useDeleteSelectedProduct;
