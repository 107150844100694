import React, { useCallback, Fragment, useMemo } from 'react';
import compact from 'lodash/compact';
import map from 'lodash/map';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import { AttachmentsListItemListViewItem } from './AttachmentsListItemListView.types';
import { AttachmentsListAttachments } from '../../../AttachmentsList/AttachmentsList.types';
import { UpdateFileAttachmentCacheQuery } from '../../../../../../fileAttachments/fileAttachmentsTypes';
import { TaskNanoID } from '../../../../../../tasks/tasksTypes';
import { ProjectNanoID } from '../../../../../../projects/projectsTypes';

import { FetchFileAttachmentsQueryResponse } from '../../../../../../fileAttachments/queries/fetchFileAttachments.query';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { CommonPermissions } from '../../../../../commonConstants';

import { AttachmentsListItemDropdownButtons } from '../AttachmentsListItemDropdownButtons';
import { UpdateFileAttachmentVisibleForClientButton } from '../../../../../../fileAttachments/components/buttons/UpdateFileAttachmentVisibleForClientButton';

import { ModelViewerModalButton } from '../../../../../../modelViewers/components/modalButtons/ModelViewerModalButton';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';
import { Translate } from '../../../../../../../helpers/Translate';
import { DateTimeHelper } from '../../../../../../../helpers/DateTimeHelper';
import { Icon } from '../../../../../../../helpers/Icon';
import { LinkHelper } from '../../../../../../../helpers/links/LinkHelper';
import { Checkbox } from '../../../../../../../helpers/Checkbox';
import { UserAvatarLink } from '../../../../../helpers/UserAvatarLink';
import { SizeHelper } from '../../../../../../../helpers/SizeHelper';
import {
  FileAttachmentItemImageVersions,
  ImageHelper
} from '../../../../../../../helpers/ImageHelper';

import { Files } from '../../../../../../../utils/Files';

import { words } from '../../../../../../../locales/keys';
import { FileAttachmentTypesEnum } from '../../../../../../fileAttachments/fileAttachmentsConstants';
import { TasksPermissions } from '../../../../../../tasks/tasksConstants';
import { ProjectsPermissions } from '../../../../../../projects/projectsConstants';

type LinkHelperOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => void;

interface AttachmentsListItemListViewProps {
  item: AttachmentsListItemListViewItem;
  items?: AttachmentsListAttachments;
  handleCheck: () => void;
  checkboxChecked: boolean;
  checkable: boolean;
  modalMode?: boolean;
  onLightboxOpen?: () => void;
  cacheKeys: string[];
  taskNanoId?: TaskNanoID;
  projectNanoId?: ProjectNanoID;
  updateAttachmentCache?: UpdateFileAttachmentCacheQuery<FetchFileAttachmentsQueryResponse>;
}

function AttachmentsListItemListView({
  item,
  items,
  handleCheck,
  checkboxChecked,
  checkable,
  modalMode,
  onLightboxOpen,
  cacheKeys,
  updateAttachmentCache,
  taskNanoId,
  projectNanoId
}: AttachmentsListItemListViewProps) {
  const currentUser = useCurrentUser();

  const handleClick = useCallback<LinkHelperOnClick>(
    (e) => {
      if (onLightboxOpen) {
        e.preventDefault();
        onLightboxOpen();
        return;
      }

      if (modalMode) {
        e.preventDefault();
        handleCheck();
      }
    },
    [handleCheck, modalMode, onLightboxOpen]
  );

  const models = useMemo(
    () =>
      compact(
        map(items, (item) =>
          Files.isPreview3dModel(item.href)
            ? {
                id: item.fileUuid,
                file: item.href,
                name: item.name
              }
            : null
        )
      ),
    [items]
  );

  const withView3dModel = Files.isPreview3dModel(item.href);

  return (
    <div className="flex py-1 2xl:py-1.5 -mx-2 sm:-mx-4 px-4 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-850">
      <div className="flex items-center flex-1 space-x-2 2xl:space-x-4">
        {checkable && (
          <div className="flex items-center">
            <Checkbox checked={checkboxChecked} onChange={handleCheck} />
          </div>
        )}

        {Files.isImage(item.href) ? (
          <div className="h-6 w-6 flex rounded overflow-hidden">
            <ImageHelper
              className="w-full h-full object-cover"
              src={item.href}
              alt={item.name}
              version={FileAttachmentItemImageVersions.MiniThumb144x144}
            />
          </div>
        ) : null}

        {!Files.isImage(item.href) && item.icon ? (
          <Icon icon={item.icon} className="h-5 w-5" />
        ) : null}

        <div className="flex flex-1 relative h-6">
          <div className="absolute inset-0 flex items-center gap-4">
            <LinkHelper
              href={item.href}
              target={item.target}
              className="flex-1 flex truncate leading-6"
              onClick={handleClick}
            >
              <span className="truncate">{item.name}</span>
            </LinkHelper>
            <div className="flex items-center max-w-max w-1/2 gap-2">
              {item.description ? (
                <Fragment>
                  <div className="hidden lg:block flex-1 truncate leading-6">
                    {item.description}
                  </div>
                  <div className="lg:hidden">
                    <Icon icon={IconsEnum.CHAT_OUTLINE} />
                  </div>
                </Fragment>
              ) : null}
              {item.nda ? (
                <span className="px-1.5 py-0.5 rounded text-xs bg-yellow-200 text-black">
                  <Translate id={words.nda} />
                </span>
              ) : null}
            </div>
          </div>
        </div>

        <CheckPermissions
          action={CommonPermissions.READ_ATTACHMENTS_LIST_SIZE_FIELD}
        >
          {item.size ? (
            <div className="hidden sm:block w-28 text-right whitespace-nowrap">
              <SizeHelper size={item.size} />
            </div>
          ) : null}
        </CheckPermissions>

        {withView3dModel && (
          <CheckPermissions
            action={
              taskNanoId
                ? TasksPermissions.READ_TASKS_ATTACHMENTS_VIEW_3D_MODEL_BUTTON
                : ProjectsPermissions.READ_PROJECTS_ATTACHMENTS_VIEW_3D_MODEL_BUTTON
            }
          >
            <ModelViewerModalButton
              className="p-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium focus:ring-base hover:text-gray-950 dark:hover:text-white focus:ring-offset-0 dark:hover:bg-gray-700 hover:bg-gray-200 leading-5 dark:text-gray-100 text-gray-900"
              icon={IconsEnum.CUBE_OUTLINE}
              iconClassName="w-5 h-5"
              tooltipI18nText={words.view3DModel}
              initialModelIndex={models.findIndex(
                (file) => item.fileUuid === file.id
              )}
              models={models}
              cameraControls
              withDownloadButton
            />
          </CheckPermissions>
        )}

        {item.type === FileAttachmentTypesEnum.FILES ||
        item.type === FileAttachmentTypesEnum.IMAGES ? (
          <CheckPermissions
            action={TasksPermissions.READ_FILE_ATTACHMENT_VISIBLE_FOR_CLIENTS}
          >
            <UpdateFileAttachmentVisibleForClientButton
              containerClassName="block w-8 text-right whitespace-nowrap text-gray-600"
              addClassName="leading-5 dark:text-gray-100 text-gray-900"
              item={item}
              iconClassName="h-5 w-5"
              cacheKeys={cacheKeys}
              disabled={
                !currentUser.hasPermissions(
                  TasksPermissions.CHANGE_FILE_ATTACHMENT_VISIBLE_FOR_CLIENTS
                )
              }
              updateAttachmentCache={updateAttachmentCache}
            />
          </CheckPermissions>
        ) : null}

        <CheckPermissions
          action={CommonPermissions.READ_ATTACHMENTS_LIST_DATE_FIELD}
        >
          {item.date ? (
            <div className="hidden sm:block w-min text-right whitespace-nowrap">
              <DateTimeHelper date={item.date} />
            </div>
          ) : null}
        </CheckPermissions>
        <CheckPermissions
          action={CommonPermissions.READ_ATTACHMENTS_LIST_USER_FIELD}
        >
          {item.user ? (
            <div className="hidden md:block w-8 text-right whitespace-nowrap">
              <UserAvatarLink
                className="h-6 w-6 ml-2 flex rounded-full overflow-hidden focus:ring-base"
                user={item.user}
              />
            </div>
          ) : null}
        </CheckPermissions>
        <AttachmentsListItemDropdownButtons
          item={item}
          items={items}
          cacheKeys={cacheKeys}
          taskNanoId={taskNanoId}
        />
      </div>
    </div>
  );
}

export default AttachmentsListItemListView;
