import { FileAttachmentUUID } from '../../../fileAttachmentsTypes';
import { UserNanoID } from '../../../../users/usersTypes';

import { useFileAttachmentAssignTypeForm } from '../../../hooks/useFileAttachmentAssignTypeForm';

import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';
import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { SelectField } from '../../../../../helpers/FormFields/SelectField';

import { attachmentsKeys, words } from '../../../../../locales/keys';

import { FileAttachmentTypesEnum } from '../../../fileAttachmentsConstants';

interface FileAttachmentAssignTypeModalButtonProps {
  attachmentUuid: FileAttachmentUUID;
  userNanoId: UserNanoID;
}

const UPDATE_FILE_ATTACHMENT_TYPE_FORM = 'update-file-attachment-type-form';

const fileAttachmentTypes = [
  {
    value: FileAttachmentTypesEnum.THREED,
    i18nLabel: attachmentsKeys.threeD
  },
  {
    value: FileAttachmentTypesEnum.REFERENCE,
    i18nLabel: attachmentsKeys.reference
  },
  {
    value: FileAttachmentTypesEnum.TEXTURE,
    i18nLabel: attachmentsKeys.texture
  },
  {
    value: FileAttachmentTypesEnum.DRAWING,
    i18nLabel: attachmentsKeys.drawing
  }
];

function FileAttachmentAssignTypeModalButton({
  attachmentUuid,
  userNanoId
}: FileAttachmentAssignTypeModalButtonProps) {
  const {
    control,
    handleUpdateFileAttachmentType,
    resetForm,
    updateFileAttachmentLoading,
    updateFileAttachmentErrorMessage
  } = useFileAttachmentAssignTypeForm({
    attachmentUuid,
    userNanoId
  });

  return (
    <FormModalButton
      className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
      form={UPDATE_FILE_ATTACHMENT_TYPE_FORM}
      i18nSubmitText={words.save}
      i18nText={attachmentsKeys.assignType}
      i18nTitle={attachmentsKeys.assignType}
      onSubmit={handleUpdateFileAttachmentType}
      disabled={updateFileAttachmentLoading}
      onOpen={resetForm}
    >
      <div className="p-4 space-y-4">
        <SelectField
          control={control}
          i18nPlaceholder={attachmentsKeys.assignType}
          options={fileAttachmentTypes}
          name="fileType"
        />
      </div>

      <div className="px-4">
        <AlertMessage message={updateFileAttachmentErrorMessage} />
      </div>
    </FormModalButton>
  );
}

export default FileAttachmentAssignTypeModalButton;
