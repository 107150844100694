import React, { useCallback } from 'react';

import { ClassName, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import {
  DeleteSelectedLifestyleCacheKeys,
  SelectedLifestyleUUID
} from '../../../selectedLifestylesTypes';

import { DELETE_SELECTED_LIFESTYLE_QUERY } from '../../../queries/deleteSelectedLifestyle.query';

import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';
import { useDeleteSelectedLifestyle } from '../../../hooks/useDeleteSelectedLifestyle';

import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';

interface DeleteSelectedLifestyleButtonProps {
  cacheKeys: DeleteSelectedLifestyleCacheKeys;
  className?: ClassName;
  i18nText?: I18nText;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  selectedLifestyleUuid: SelectedLifestyleUUID;
}

function DeleteSelectedLifestyleButton({
  cacheKeys,
  className,
  i18nText,
  icon,
  iconClassName,
  selectedLifestyleUuid
}: DeleteSelectedLifestyleButtonProps) {
  const {
    deleteSelectedLifestyle,
    deleteSelectedLifestyleLoading,
    deleteSelectedLifestyleErrorMessage
  } = useDeleteSelectedLifestyle({
    cacheKeys,
    query: DELETE_SELECTED_LIFESTYLE_QUERY
  });

  const handleDeleteSelectedLifestyle = useCallback(
    () =>
      deleteSelectedLifestyle({ uuid: selectedLifestyleUuid }).catch((error) =>
        console.log(error)
      ),
    [deleteSelectedLifestyle, selectedLifestyleUuid]
  );

  useShowToastOnErrorChange({ error: deleteSelectedLifestyleErrorMessage });

  return (
    <PureIconButtonHelper
      className={className}
      disabled={deleteSelectedLifestyleLoading}
      i18nText={i18nText}
      icon={icon}
      iconClassName={iconClassName}
      onClick={handleDeleteSelectedLifestyle}
    />
  );
}

export default DeleteSelectedLifestyleButton;
