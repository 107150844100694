import { useCallback } from 'react';
import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';
import {
  FileAttachmentUUID,
  FileAttachmentVisibleForClients,
  UpdateFileAttachmentCacheQuery
} from '../../fileAttachmentsTypes';

interface UpdateFileAttachmentVisibleForClientOptions<
  UpdateFileAttachmentVisibleForClientRecordType
> {
  cacheKeys: string[];
  query: string;
  updateAttachmentCache: UpdateFileAttachmentCacheQuery<UpdateFileAttachmentVisibleForClientRecordType>;
}

interface UpdateFileAttachmentVisibleForClientInput {
  uuid: FileAttachmentUUID;
  visibleForClients: FileAttachmentVisibleForClients;
}

interface UpdateFileAttachmentVisibleForClientError {
  fullMessages: string[] | null;
}

interface UpdateFileAttachmentVisibleForClientResponse<
  UpdateFileAttachmentVisibleForClientRecordType
> {
  updateFileMessageVisibleForClient: {
    status: string;
    record: UpdateFileAttachmentVisibleForClientRecordType;
    errors: UpdateFileAttachmentVisibleForClientError;
  };
}

const action = 'updateFileAttachmentVisibleForClients';

function useUpdateFileAttachmentVisibleForClient<
  UpdateFileAttachmentVisibleForClientRecordType
>({
  cacheKeys,
  query,
  updateAttachmentCache
}: UpdateFileAttachmentVisibleForClientOptions<UpdateFileAttachmentVisibleForClientRecordType>) {
  const handleOptimisticUpdate = useCallback<
    (input: UpdateFileAttachmentVisibleForClientInput) => null | (() => void)
  >(
    (input) => {
      return updateAttachmentCache?.({
        selector: { uuid: input?.uuid },
        updateFunction: (prevAttachment) => ({
          ...prevAttachment,
          visibleForClients: input.visibleForClients
        })
      });
    },
    [updateAttachmentCache]
  );

  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    UpdateFileAttachmentVisibleForClientInput,
    UpdateFileAttachmentVisibleForClientResponse<UpdateFileAttachmentVisibleForClientRecordType>,
    UpdateFileAttachmentVisibleForClientError,
    UpdateFileAttachmentVisibleForClientRecordType
  >({
    action,
    cacheKeys,
    query,
    onOptimisticUpdate: updateAttachmentCache
      ? handleOptimisticUpdate
      : undefined
  });

  return {
    updateFileAttachmentVisibleForClientData: updateQueryData,
    updateFileAttachmentVisibleForClientError: updateQueryError,
    updateFileAttachmentVisibleForClientLoading: updateQueryLoading,
    updateFileAttachmentVisibleForClientErrorMessage: updateQueryErrorMessage,
    updateFileAttachmentVisibleForClient: updateQuery,
    updateFileAttachmentVisibleForClientReset: updateQueryReset
  };
}

export default useUpdateFileAttachmentVisibleForClient;
