import React, { useMemo } from 'react';
import cl from 'classnames';
import map from 'lodash/map';
import compact from 'lodash/compact';

import { PositionStyleEnum } from '../../../../../../../types';
import { IconsEnum } from '../../../../../../../assets/icons/types';
import { AttachmentsListAttachments } from '../../../AttachmentsList/AttachmentsList.types';
import { AttachmentsListItemDropdownButtonsItem } from './AttachmentsListItemDropdownButtons.types';
import { TaskNanoID } from '../../../../../../tasks/tasksTypes';
import { UserNanoID } from '../../../../../../users/usersTypes';

import { usePreventModalClose } from '../../../../../../../helpers/modals/hooks/usePreventModalClose';
import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { EditImageAndSendToTaskModalButton } from '../../../../../../tasks/components/modalButtons/EditImageAndSendToTaskModalButton';
import { EditImageAndSendToProjectModalButton } from '../../../../../../projects/components/modalButtons/EditImageAndSendToProjectModalButton';
import { AttachmentsListItemDropdownDownloadButtons } from './components/AttachmentsListItemDropdownDownloadButtons';
import { AttachmentsListItemDropdownDeleteButtons } from './components/AttachmentsListItemDropdownDeleteButtons';
import { FileAttachmentAssignTypeModalButton } from '../../../../../../fileAttachments/components/buttons/FileAttachmentAssignTypeModalButton';
import { ModelViewerModalButton } from '../../../../../../modelViewers/components/modalButtons/ModelViewerModalButton';

import { DropdownHelper } from '../../../../../../../helpers/dropdowns/DropdownHelper';
import { PopoverPlacement } from '../../../../../../../helpers/Popover/popoverConstants';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import { Files } from '../../../../../../../utils/Files';

import { words } from '../../../../../../../locales/keys';
import { TasksPermissions } from '../../../../../../tasks/tasksConstants';
import { ProjectsPermissions } from '../../../../../../projects/projectsConstants';
import { UsersPermissions } from '../../../../../../users/usersConstants';

interface AttachmentsListItemDropdownButtonsProps {
  item: AttachmentsListItemDropdownButtonsItem;
  items?: AttachmentsListAttachments;
  isGridView?: boolean;
  cacheKeys: string[];
  taskNanoId: TaskNanoID;
  userNanoId?: UserNanoID;
}

function AttachmentsListItemDropdownButtons({
  item,
  items,
  isGridView,
  cacheKeys,
  taskNanoId,
  userNanoId
}: AttachmentsListItemDropdownButtonsProps) {
  const currentUser = useCurrentUser();

  const { togglePreventModalClose, preventModalClose } = usePreventModalClose();

  const models = useMemo(
    () =>
      compact(
        map(items, (item) =>
          Files.isPreview3dModel(item.href)
            ? {
                id: item.fileUuid,
                file: item.href,
                name: item.name
              }
            : null
        )
      ),
    [items]
  );

  const withView3dModel = Files.isPreview3dModel(item.href);

  return (
    <DropdownHelper
      className={cl(
        'relative',
        isGridView ? 'pointer-events-auto' : 'flex items-center'
      )}
      buttonClassName={cl(
        'py-1 pl-1 pr-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0',
        isGridView ? null : '-my-0.5'
      )}
      icon={IconsEnum.DOTS_HORIZONTAL_OUTLINE}
      iconClassName="h-5 w-5"
      dropdownPlacement={PopoverPlacement.BOTTOM_END}
      popoverPositionStyle={PositionStyleEnum.fixed}
      closeOnOuterClick={!preventModalClose}
    >
      {item.editedImageTaskNanoId && !item.editedImageProjectNanoId ? (
        <EditImageAndSendToTaskModalButton
          file={item.href}
          taskNanoId={item.editedImageTaskNanoId}
          className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
          i18nText={words.edit}
          toggleImageEditorBackdrop={togglePreventModalClose}
        />
      ) : null}
      {!item.editedImageTaskNanoId && item.editedImageProjectNanoId ? (
        <EditImageAndSendToProjectModalButton
          file={item.href}
          projectNanoId={item.editedImageProjectNanoId}
          className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
          i18nText={words.edit}
          toggleImageEditorBackdrop={togglePreventModalClose}
        />
      ) : null}

      <AttachmentsListItemDropdownDownloadButtons item={item} />

      {withView3dModel && (
        <CheckPermissions
          action={
            taskNanoId
              ? TasksPermissions.READ_TASKS_ATTACHMENTS_VIEW_3D_MODEL_BUTTON
              : ProjectsPermissions.READ_PROJECTS_ATTACHMENTS_VIEW_3D_MODEL_BUTTON
          }
        >
          <ModelViewerModalButton
            className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
            i18nText={words.view3DModel}
            initialModelIndex={models.findIndex(
              (file) => item.fileUuid === file.id
            )}
            models={models}
            cameraControls
            withDownloadButton
          />
        </CheckPermissions>
      )}

      {userNanoId && (
        <CheckPermissions
          action={
            userNanoId === currentUser.nanoId
              ? UsersPermissions.READ_USER_PROFILE_FILE_ASSIGN_TYPE_BUTTON
              : UsersPermissions.READ_SELF_PROFILE_FILE_ASSIGN_TYPE_BUTTON
          }
        >
          <FileAttachmentAssignTypeModalButton
            attachmentUuid={item.fileUuid}
            userNanoId={userNanoId}
          />
        </CheckPermissions>
      )}

      <CheckPermissions
        action={
          taskNanoId
            ? TasksPermissions.READ_TASKS_ATTACHMENTS_REMOVE_FILE_BUTTON
            : ProjectsPermissions.READ_PROJECTS_ATTACHMENTS_REMOVE_FILE_BUTTON
        }
      >
        <AttachmentsListItemDropdownDeleteButtons
          item={item}
          cacheKeys={cacheKeys}
          taskNanoId={taskNanoId}
        />
      </CheckPermissions>
    </DropdownHelper>
  );
}

export default AttachmentsListItemDropdownButtons;
