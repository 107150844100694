import React, { useCallback } from 'react';

import { ClassName, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import {
  DeleteSelectedProductCacheKeys,
  SelectedProductUUID
} from '../../../selectedProductsTypes';

import { DELETE_SELECTED_PRODUCT_QUERY } from '../../../queries/deleteSelectedProduct.query';

import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';
import { useDeleteSelectedProduct } from '../../../hooks/useDeleteSelectedProduct';

import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';

interface DeleteSelectedProductButtonProps {
  cacheKeys: DeleteSelectedProductCacheKeys;
  className?: ClassName;
  i18nText?: I18nText;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  selectedProductUuid: SelectedProductUUID;
}

function DeleteSelectedProductButton({
  cacheKeys,
  className,
  i18nText,
  icon,
  iconClassName,
  selectedProductUuid
}: DeleteSelectedProductButtonProps) {
  const {
    deleteSelectedProduct,
    deleteSelectedProductLoading,
    deleteSelectedProductErrorMessage
  } = useDeleteSelectedProduct({
    cacheKeys,
    query: DELETE_SELECTED_PRODUCT_QUERY
  });

  const handleDeleteSelectedProduct = useCallback(
    () =>
      deleteSelectedProduct({ uuid: selectedProductUuid }).catch((error) =>
        console.log(error)
      ),
    [deleteSelectedProduct, selectedProductUuid]
  );

  useShowToastOnErrorChange({ error: deleteSelectedProductErrorMessage });

  return (
    <PureIconButtonHelper
      className={className}
      disabled={deleteSelectedProductLoading}
      i18nText={i18nText}
      icon={icon}
      iconClassName={iconClassName}
      onClick={handleDeleteSelectedProduct}
    />
  );
}

export default DeleteSelectedProductButton;
