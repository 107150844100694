import React, { useCallback } from 'react';

import { ClassName, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import { SourceFileUUID } from '../../../sourceFilesTypes';

import { DOWNLOAD_SOURCE_FILE_QUERY } from '../../../queries/downloadSourceFile.query';

import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';
import { useDownloadNanoId } from '../../../../downloads/hooks/useDownloadNanoId';
import { useDownloadSourceFile } from '../../../hooks/useDownloadSourceFile';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { DownloadCache } from '../../../../downloads/DownloadCache';

interface DownloadSourceFileButtonProps {
  sourceFileUuid: SourceFileUUID;
  className?: ClassName;
  iconClassName?: ClassName;
  icon?: IconsEnum;
  i18nText?: I18nText;
  tooltipI18nText?: I18nText;
  tooltipPlacement?: TooltipPlacement;
  tooltipSingleton?: boolean;
}

function DownloadSourceFileButton({
  sourceFileUuid,
  className,
  iconClassName,
  icon,
  tooltipI18nText,
  tooltipPlacement,
  tooltipSingleton,
  i18nText
}: DownloadSourceFileButtonProps) {
  const {
    downloadSourceFileLoading,
    downloadSourceFileErrorMessage,
    downloadSourceFile
  } = useDownloadSourceFile({
    query: DOWNLOAD_SOURCE_FILE_QUERY,
    cacheKeys: [DownloadCache.indexCacheKey()]
  });

  useShowToastOnErrorChange({ error: downloadSourceFileErrorMessage });

  const { downloadNanoId } = useDownloadNanoId();

  const handleClick = useCallback(() => {
    downloadSourceFile({
      uuid: sourceFileUuid,
      deviceNanoId: downloadNanoId
    });
  }, [downloadSourceFile, sourceFileUuid, downloadNanoId]);

  return (
    <PureTooltipIconButtonHelper
      className={className}
      i18nText={i18nText}
      iconClassName={iconClassName}
      icon={icon}
      tooltipI18nText={tooltipI18nText}
      tooltipPlacement={tooltipPlacement}
      disabled={downloadSourceFileLoading}
      onClick={handleClick}
      tooltipSingleton={tooltipSingleton}
    />
  );
}

export default DownloadSourceFileButton;
