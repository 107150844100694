import { gql } from 'graphql-request';

export const DELETE_SELECTED_MATERIAL_QUERY = gql`
  mutation DeleteSelectedMaterial($uuid: ID!) {
    deleteSelectedMaterial(input: { uuid: $uuid }) {
      status
      errors {
        fullMessages
      }
    }
  }
`;
