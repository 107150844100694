import React, { useCallback } from 'react';
import cl from 'classnames';

import { ClassName } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import { UpdateFileAttachmentCacheQuery } from '../../../fileAttachmentsTypes';

import {
  UpdateFileAttachmentVisibleForClientsType,
  UPDATE_FILE_ATTACHMENT_VISIBLE_FOR_CLIENT_QUERY
} from '../../../queries/updateFileAttachmentVisibleForClients.query';
import { FetchFileAttachmentsQueryResponse } from '../../../queries/fetchFileAttachments.query';

import { useUpdateFileAttachmentVisibleForClient } from '../../../hooks/useUpdateFileAttachmentVisibleForClients';

import { AttachmentsListItemDropdownButtonsItem } from '../../../../common/components/lists/AttachmentsListItem/components/AttachmentsListItemDropdownButtons';

import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';

interface UpdateFileAttachmentVisibleForClientButtonProps {
  item: AttachmentsListItemDropdownButtonsItem;
  className?: ClassName;
  addClassName?: ClassName;
  containerClassName?: ClassName;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  cacheKeys: string[];
  disabled: boolean;
  updateAttachmentCache?: UpdateFileAttachmentCacheQuery<FetchFileAttachmentsQueryResponse>;
}

function UpdateFileAttachmentVisibleForClientButton({
  item,
  className,
  icon,
  addClassName,
  containerClassName,
  iconClassName = 'h-3.5 w-3.5',
  cacheKeys,
  disabled,
  updateAttachmentCache
}: UpdateFileAttachmentVisibleForClientButtonProps) {
  const {
    updateFileAttachmentVisibleForClient,
    updateFileAttachmentVisibleForClientLoading
  } = useUpdateFileAttachmentVisibleForClient<UpdateFileAttachmentVisibleForClientsType>(
    {
      cacheKeys,
      query: UPDATE_FILE_ATTACHMENT_VISIBLE_FOR_CLIENT_QUERY,
      updateAttachmentCache
    }
  );

  const handleUpdateFileAttachmentVisibleForClient = useCallback<() => void>(
    () =>
      updateFileAttachmentVisibleForClient({
        uuid: item.fileUuid,
        visibleForClients: !item.visibleForClients
      }),
    [updateFileAttachmentVisibleForClient, item]
  );

  const visibilityButtonIcon =
    icon ||
    (item.visibleForClients ? IconsEnum.EYE_SOLID : IconsEnum.EYE_CROSSED);

  return (
    <div className={containerClassName}>
      <PureIconButtonHelper
        icon={visibilityButtonIcon}
        className={cl(
          className ||
            'p-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium focus:ring-base hover:text-gray-950 dark:hover:text-white focus:ring-offset-0 dark:hover:bg-gray-700 hover:bg-gray-200',
          addClassName ||
            'leading-6 hover:bg-gray-200 pointer-events-auto z-10 bg-white dark:bg-gray-900 dark:text-gray-300 text-gray-700 shadow dark:glow'
        )}
        iconClassName={iconClassName}
        disabled={disabled || updateFileAttachmentVisibleForClientLoading}
        onClick={handleUpdateFileAttachmentVisibleForClient}
      />
    </div>
  );
}

export default UpdateFileAttachmentVisibleForClientButton;
