import React, { useCallback } from 'react';

import { ClassName, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import {
  FileAttachmentUUID,
  RemoveFileAttachmentCacheKeys
} from '../../../fileAttachmentsTypes';

import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';
import { useRemoveFileAttachment } from '../../../hooks/useRemoveFileAttachment';

import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';

import { MessageCache } from '../../../../messages/MessageCache';

import { REMOVE_FILE_ATTACHMENT_QUERY } from '../../../queries/removeFileAttachment.query';

interface RemoveFileAttachmentButtonProps {
  cacheKeys?: RemoveFileAttachmentCacheKeys;
  className?: ClassName;
  i18nText?: I18nText;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  fileAttachmentUuid: FileAttachmentUUID;
}

function RemoveFileAttachmentButton({
  cacheKeys,
  className,
  i18nText,
  icon,
  iconClassName,
  fileAttachmentUuid
}: RemoveFileAttachmentButtonProps) {
  const {
    removeFileAttachment,
    removeFileAttachmentLoading,
    removeFileAttachmentErrorMessage
  } = useRemoveFileAttachment({
    cacheKeys: cacheKeys || [MessageCache.indexCacheKey()],
    query: REMOVE_FILE_ATTACHMENT_QUERY
  });

  const handleRemoveFileAttachment = useCallback(
    () =>
      removeFileAttachment({ uuid: fileAttachmentUuid }).catch((error) =>
        console.log(error)
      ),
    [removeFileAttachment, fileAttachmentUuid]
  );

  useShowToastOnErrorChange({ error: removeFileAttachmentErrorMessage });

  return (
    <PureIconButtonHelper
      className={className}
      disabled={removeFileAttachmentLoading}
      i18nText={i18nText}
      icon={icon}
      iconClassName={iconClassName}
      onClick={handleRemoveFileAttachment}
    />
  );
}

export default RemoveFileAttachmentButton;
