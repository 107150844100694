import {
  DeleteSourceFileGqlQuery,
  DeleteSourceFileGqlStatus,
  FetchSourceFilesCacheKey,
  SourceFileGqlError,
  SourceFileUUID
} from '../../sourceFilesTypes';

import { useDeleteQuery } from '../../../common/hooks/base/reactQuery/useDeleteQuery';

interface DeleteSourceFileOptions {
  query: DeleteSourceFileGqlQuery;
  cacheKeys?: FetchSourceFilesCacheKey[];
}

export interface DeleteSourceFileResponse<DeleteSourceFileRecordType> {
  deleteSourceFile: {
    status: DeleteSourceFileGqlStatus;
    errors: DeleteSourceFileError;
  };
}

export interface DeleteSourceFileInput {
  uuid: SourceFileUUID;
}

export interface DeleteSourceFileError {
  fullMessages: SourceFileGqlError;
}

const action = 'deleteSourceFile';

function useDeleteSourceFile<DeleteSourceFileRecordType>({
  query,
  cacheKeys
}: DeleteSourceFileOptions) {
  const {
    deleteQueryData,
    deleteQuery,
    deleteQueryReset,
    deleteQueryError,
    deleteQueryLoading,
    deleteQueryErrorMessage
  } = useDeleteQuery<
    DeleteSourceFileInput,
    DeleteSourceFileResponse<DeleteSourceFileRecordType>,
    DeleteSourceFileError
  >({ action, cacheKeys, query });

  return {
    deleteSourceFileData: deleteQueryData,
    deleteSourceFileError: deleteQueryError,
    deleteSourceFileLoading: deleteQueryLoading,
    deleteSourceFileErrorMessage: deleteQueryErrorMessage,
    deleteSourceFile: deleteQuery,
    deleteSourceFileReset: deleteQueryReset
  };
}

export default useDeleteSourceFile;
