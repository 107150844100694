import React, { useCallback } from 'react';
import cl from 'classnames';

import {
  BreadcrumbsUrl,
  BreadcrumbsUrls,
  I18nText,
  PathUrl
} from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';

import {
  Checkable,
  Checked,
  OnCheckAll
} from '../../../../../common/hooks/useTableCheckable';

import { FoldersListItemBreadCrumbs } from './components/FoldersListItemBreadCrumbs';

import { NextPureLinkHelper } from '../../../../../helpers/links/NextPureLinkHelper';
import { Translate } from '../../../../../helpers/Translate';
import { Checkbox } from '../../../../../helpers/Checkbox';

interface FoldersListBreadCrumbsDefaultProps {
  hrefItems?: BreadcrumbsUrls;
  backHref?: PathUrl;
  checked?: Checked;
  onCheck?: OnCheckAll;
  checkable?: Checkable;
  onClickBack?: () => void;
  onClickBreadCrumbsHref?: (href: BreadcrumbsUrl) => void;
}

interface FoldersListBreadCrumbsPropsWithText {
  text: string;
  i18nText?: never;
}

interface FoldersListBreadCrumbsPropsWithI18nText {
  text?: never;
  i18nText: I18nText;
}

type FoldersListBreadCrumbsProps = FoldersListBreadCrumbsDefaultProps &
  (
    | FoldersListBreadCrumbsPropsWithText
    | FoldersListBreadCrumbsPropsWithI18nText
  );

function FoldersListBreadCrumbs({
  hrefItems,
  backHref,
  text,
  i18nText,
  checked,
  onCheck,
  checkable,
  onClickBack,
  onClickBreadCrumbsHref
}: FoldersListBreadCrumbsProps) {
  const handleClickBack = useCallback<
    (e: React.MouseEvent<HTMLAnchorElement>) => void
  >(
    (e) => {
      if (onClickBack) {
        e.preventDefault();
        onClickBack();
      }
    },
    [onClickBack]
  );

  return (
    <div className="flex items-center h-10">
      {checkable && (
        <div className="flex items-center">
          <Checkbox checked={checked} onChange={onCheck} />
        </div>
      )}
      {backHref ? (
        <NextPureLinkHelper
          className="py-2 pl-2 pr-2 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 -mr-2.5"
          href={backHref}
          icon={IconsEnum.ARROW_SM_LEFT_OUTLINE}
          onClick={handleClickBack}
        />
      ) : null}
      <div
        className={cl(
          'flex-1 truncate flex pr-1 h-full items-center text-base xl:text-lg text-gray-500',
          {
            'pl-3': backHref || checkable
          }
        )}
      >
        <span>
          {hrefItems?.map((href) => (
            <FoldersListItemBreadCrumbs
              key={href.pathUrl}
              href={href}
              onClickItem={onClickBreadCrumbsHref}
            />
          ))}
          <span className="font-medium text-black dark:text-white">
            {i18nText ? <Translate id={i18nText} /> : text}
          </span>
        </span>
      </div>
    </div>
  );
}

export default FoldersListBreadCrumbs;
