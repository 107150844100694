import { FileUrl } from '../../../../../../../../../types';

import { IconsEnum } from '../../../../../../../../../assets/icons/types';

import { Files } from '../../../../../../../../../utils/Files';

function getPreviewIcon(src: FileUrl): IconsEnum {
  if (Files.isTableFile(src)) return IconsEnum.DOCUMENT_TABLE_OUTLINE;
  if (Files.isTextFile(src)) return IconsEnum.DOCUMENT_TEXT_OUTLINE;
  if (Files.isImage(src)) return IconsEnum.PHOTOGRAPH_OUTLINE;
  return IconsEnum.DOCUMENT_OUTLINE;
}

export default getPreviewIcon;
