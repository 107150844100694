import { gql } from 'graphql-request';

export const REMOVE_FILE_ATTACHMENT_QUERY = gql`
  mutation RemoveFileAttachment($uuid: ID!) {
    removeFileAttachment(input: { uuid: $uuid }) {
      status
      errors {
        fullMessages
      }
    }
  }
`;
