import { AttachmentsListItemDropdownDownloadItem } from './AttachmentsListItemDropdownDownloadButtons.types';

import { DownloadLifestyleButton } from '../../../../../../../../lifestyles/components/buttons/DownloadLifestyleButton';
import { DownloadProductButton } from '../../../../../../../../products/components/buttons/DownloadProductButton';
import { DownloadMaterialButton } from '../../../../../../../../materials/components/buttons/DownloadMaterialButton';
import { DownloadSourceFileButton } from '../../../../../../../../sourceFiles/components/buttons/DownloadSourceFileButton';
import { DownloadFileAttachmentButton } from '../../../../../../../../fileAttachments/components/buttons/DownloadFileAttachmentButton';

import { words } from '../../../../../../../../../locales/keys';
import { FileAttachmentTypesEnum } from '../../../../../../../../fileAttachments/fileAttachmentsConstants';

interface AttachmentsListItemDropdownDownloadButtonsProps {
  item: AttachmentsListItemDropdownDownloadItem;
}

function AttachmentsListItemDropdownDownloadButtons({
  item
}: AttachmentsListItemDropdownDownloadButtonsProps) {
  if (item.lifestyleUuid) {
    return (
      <DownloadLifestyleButton
        i18nText={words.download}
        className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
        lifestyleUuid={item.lifestyleUuid}
      />
    );
  }

  if (item.productUuid) {
    return (
      <DownloadProductButton
        i18nText={words.download}
        className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
        productUuid={item.productUuid}
      />
    );
  }

  if (item.materialUuid) {
    return (
      <DownloadMaterialButton
        i18nText={words.download}
        className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
        materialUuid={item.materialUuid}
      />
    );
  }

  if (item.type === FileAttachmentTypesEnum.SOURCE_FILES) {
    return (
      <DownloadSourceFileButton
        i18nText={words.download}
        className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
        sourceFileUuid={item.fileUuid}
      />
    );
  }

  return (
    <DownloadFileAttachmentButton
      i18nText={words.download}
      className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
      fileAttachmentUuid={item.fileUuid}
    />
  );
}

export default AttachmentsListItemDropdownDownloadButtons;
