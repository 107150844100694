import { gql } from 'graphql-request';
import {
  FileAttachmentUUID,
  FileAttachmentVisibleForClients
} from '../fileAttachmentsTypes';

export interface UpdateFileAttachmentVisibleForClientsType {
  uuid: FileAttachmentUUID;
  visibleForClients: FileAttachmentVisibleForClients;
}

export const UPDATE_FILE_ATTACHMENT_VISIBLE_FOR_CLIENT_QUERY = gql`
  mutation UpdateFileAttachmentVisibleForClient(
    $uuid: ID!
    $visibleForClients: Boolean!
  ) {
    updateFileAttachmentVisibleForClients(
      input: { uuid: $uuid, visibleForClients: $visibleForClients }
    ) {
      errors {
        fullMessages
      }
      recordUuid
      status
      record {
        visibleForClients
      }
    }
  }
`;
