import { useMemo } from 'react';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import first from 'lodash/first';

import { FileAttachmentTypeBadgeType } from './FileAttachmentTypeBadge.types';

import { Translate } from '../../../../helpers/Translate';

import { attachmentsKeys } from '../../../../locales/keys';

interface AssignedTypeAttachmentItemBadgeBadge {
  item: FileAttachmentTypeBadgeType;
}

function FileAttachmentTypeBadge({
  item
}: AssignedTypeAttachmentItemBadgeBadge) {
  const fileAttachmentAssignedType = useMemo<string>(
    () =>
      first(
        compact([
          item.threeD ? attachmentsKeys.threeD : null,
          item.reference ? attachmentsKeys.reference : null,
          item.texture ? attachmentsKeys.texture : null,
          item.drawing ? attachmentsKeys.drawing : null
        ])
      ),
    [item]
  );

  if (isEmpty(fileAttachmentAssignedType)) {
    return null;
  }

  return (
    <span className="px-1.5 py-0.5 text-white rounded shadow text-xs bg-gray-600">
      <Translate id={fileAttachmentAssignedType} />
    </span>
  );
}

export default FileAttachmentTypeBadge;
