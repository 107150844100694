import React, { Fragment, useCallback } from 'react';
import cl from 'classnames';

import { BreadcrumbsUrl, I18nText } from '../../../../../../../types';

import { NextPureTooltipIconLinkHelper } from '../../../../../../../helpers/links/NextPureTooltipIconLinkHelper';
import { NextPureLinkHelper } from '../../../../../../../helpers/links/NextPureLinkHelper';

import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';

type FoldersListItemBreadCrumbsProps = {
  href?: BreadcrumbsUrl;
  i18nText?: I18nText;
  onClickItem?: (href: BreadcrumbsUrl) => void;
};

function FoldersListItemBreadCrumbs({
  href,
  onClickItem
}: FoldersListItemBreadCrumbsProps) {
  const handleClickBreadCrumbsHref = useCallback<
    (e: React.MouseEvent<HTMLAnchorElement>) => void
  >(
    (e) => {
      if (onClickItem) {
        e.preventDefault();
        onClickItem(href);
      }
    },
    [href, onClickItem]
  );

  return (
    <Fragment>
      {href.tooltipI18nText ? (
        <NextPureTooltipIconLinkHelper
          href={href.pathUrl}
          className={cl({
            'hover:underline': href.i18nText
          })}
          i18nText={href.i18nText}
          icon={href.icon}
          iconClassName="w-6 h-6 inline-block stroke-2"
          onClick={handleClickBreadCrumbsHref}
          tooltipI18nText={href.tooltipI18nText}
          tooltipPlacement={TooltipPlacement.TOP}
        />
      ) : (
        <NextPureLinkHelper
          href={href.pathUrl}
          className={cl({
            'hover:underline': href.i18nText
          })}
          i18nText={href.i18nText}
          icon={href.icon}
          iconClassName="w-6 h-6 inline-block stroke-2"
          onClick={handleClickBreadCrumbsHref}
        />
      )}{' '}
      /{' '}
    </Fragment>
  );
}

export default FoldersListItemBreadCrumbs;
