import { useCallback, useState } from 'react';

import { TogglePreventModalCloseAction } from '../../modalsTypes';

function usePreventModalClose() {
  const [preventModalClose, setPreventModalClose] = useState<boolean>(false);

  const togglePreventModalClose = useCallback<TogglePreventModalCloseAction>(
    () => setPreventModalClose((prev) => !prev),
    [setPreventModalClose]
  );

  return { preventModalClose, togglePreventModalClose };
}

export default usePreventModalClose;
