import { gql } from 'graphql-request';

export const DELETE_SELECTED_LIFESTYLE_QUERY = gql`
  mutation DeleteSelectedLifestyle($uuid: ID!) {
    deleteSelectedLifestyle(input: { uuid: $uuid }) {
      status
      errors {
        fullMessages
      }
    }
  }
`;
