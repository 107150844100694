import { FileAttachmentUUID } from '../../fileAttachmentsTypes';
import { UserNanoID } from '../../../users/usersTypes';

import {
  UPDATE_FILE_ATTACHMENT_QUERY,
  UpdateFileAttachmentQueryResponse
} from '../../queries/updateFileAttachment.query';

import { useReactHookForm } from '../../../common/hooks/base/useReactHookForm';
import { useUpdateFileAttachment } from '../useUpdateFileAttachment';

import { UserCache } from '../../../users/UserCache';

export interface FileAttachmentAssignTypeFormData {
  fileType: string;
}

interface useFileAttachmentAssignTypeFormProps {
  attachmentUuid: FileAttachmentUUID;
  userNanoId: UserNanoID;
}

function useFileAttachmentAssignTypeForm({
  attachmentUuid,
  userNanoId
}: useFileAttachmentAssignTypeFormProps) {
  const { control, handleSubmitReactHookForm, resetForm } =
    useReactHookForm<FileAttachmentAssignTypeFormData>({
      defaultValues: {
        fileType: ''
      }
    });

  const {
    updateFileAttachment,
    updateFileAttachmentLoading,
    updateFileAttachmentErrorMessage
  } = useUpdateFileAttachment<UpdateFileAttachmentQueryResponse>({
    query: UPDATE_FILE_ATTACHMENT_QUERY,
    cacheKeys: [
      UserCache.imagesAttachmentsCacheKey(userNanoId),
      UserCache.filesAttachmentsCacheKey(userNanoId)
    ]
  });

  return {
    control,
    handleUpdateFileAttachmentType: handleSubmitReactHookForm({
      onSubmit: async (data: FileAttachmentAssignTypeFormData) =>
        updateFileAttachment({
          uuid: attachmentUuid,
          drawing: data.fileType === 'drawing',
          texture: data.fileType === 'texture',
          threeD: data.fileType === 'threeD',
          reference: data.fileType === 'reference'
        })
    }),
    resetForm,
    updateFileAttachmentLoading,
    updateFileAttachmentErrorMessage
  };
}

export default useFileAttachmentAssignTypeForm;
