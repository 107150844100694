import {
  DeleteSelectedMaterialCacheKeys,
  DeleteSelectedMaterialGqlQuery,
  DeleteSelectedMaterialGqlStatus,
  SelectedMaterialGqlError,
  SelectedMaterialUUID
} from '../../selectedMaterialsTypes';

import { useDeleteQuery } from '../../../common/hooks/base/reactQuery/useDeleteQuery';

interface DeleteSelectedMaterialInput {
  uuid: SelectedMaterialUUID;
}

interface DeleteSelectedMaterialResponse {
  deleteSelectedMaterial: {
    status: DeleteSelectedMaterialGqlStatus;
    errors: DeleteSelectedMaterialError;
  };
}

interface DeleteSelectedMaterialError {
  fullMessages: SelectedMaterialGqlError;
}

interface DeleteSelectedMaterialOptions {
  cacheKeys: DeleteSelectedMaterialCacheKeys;
  query: DeleteSelectedMaterialGqlQuery;
}

const action = 'deleteSelectedMaterial';

function useDeleteSelectedMaterial({
  query,
  cacheKeys
}: DeleteSelectedMaterialOptions) {
  const {
    deleteQueryData,
    deleteQuery,
    deleteQueryReset,
    deleteQueryError,
    deleteQueryLoading,
    deleteQueryErrorMessage
  } = useDeleteQuery<
    DeleteSelectedMaterialInput,
    DeleteSelectedMaterialResponse,
    DeleteSelectedMaterialError
  >({ action, cacheKeys, query });

  return {
    deleteSelectedMaterialData: deleteQueryData,
    deleteSelectedMaterialError: deleteQueryError,
    deleteSelectedMaterialLoading: deleteQueryLoading,
    deleteSelectedMaterialErrorMessage: deleteQueryErrorMessage,
    deleteSelectedMaterial: deleteQuery,
    deleteSelectedMaterialReset: deleteQueryReset
  };
}

export default useDeleteSelectedMaterial;
