import React, { useCallback } from 'react';

import { AttachmentsListItemAttachment } from './AttachmentsListItem.types';
import { AttachmentsListAttachments } from '../AttachmentsList/AttachmentsList.types';
import { TaskNanoID } from '../../../../tasks/tasksTypes';
import { ProjectNanoID } from '../../../../projects/projectsTypes';
import { UpdateFileAttachmentCacheQuery } from '../../../../fileAttachments/fileAttachmentsTypes';
import { UserNanoID } from '../../../../users/usersTypes';

import { FetchFileAttachmentsQueryResponse } from '../../../../fileAttachments/queries/fetchFileAttachments.query';

import {
  OnSetCheckedIds,
  Checked,
  Checkable
} from '../../../../../common/hooks/useTableCheckable';

import { AttachmentsListItemListView } from './components/AttachmentsListItemListView';
import { AttachmentsListItemGridView } from './components/AttachmentsListItemGridView';

import { OnLightboxOpenAction } from '../../../../../helpers/LightboxWrapper';

import { Files } from '../../../../../utils/Files';

interface AttachmentsListItemProps {
  taskNanoId: TaskNanoID;
  projectNanoId: ProjectNanoID;
  userNanoId?: UserNanoID;
  item: AttachmentsListItemAttachment;
  items?: AttachmentsListAttachments;
  onCheck: OnSetCheckedIds;
  checked: Checked;
  checkable: Checkable;
  modalMode?: boolean;
  isGridView: boolean;
  onLightboxOpen?: OnLightboxOpenAction;
  updateAttachmentCache?: UpdateFileAttachmentCacheQuery<FetchFileAttachmentsQueryResponse>;
  cacheKeys?: string[];
}

function AttachmentsListItem({
  taskNanoId,
  projectNanoId,
  userNanoId,
  item,
  items,
  onCheck,
  checked,
  checkable,
  modalMode,
  isGridView,
  onLightboxOpen,
  updateAttachmentCache,
  cacheKeys
}: AttachmentsListItemProps) {
  const handleCheck = useCallback<() => void>(
    () => onCheck?.(item.id),
    [item, onCheck]
  );

  const withLightbox = onLightboxOpen && item.href && Files.isImage(item.href);

  const handleLightboxOpen = useCallback<() => void>(
    () =>
      withLightbox &&
      onLightboxOpen?.({ file: item.href, uuid: item.fileUuid }),
    [item, withLightbox, onLightboxOpen]
  );

  return isGridView ? (
    <AttachmentsListItemGridView
      item={item}
      items={items}
      handleCheck={handleCheck}
      checkboxChecked={checked}
      checkable={checkable}
      onLightboxOpen={withLightbox ? handleLightboxOpen : undefined}
      cacheKeys={cacheKeys}
      updateAttachmentCache={updateAttachmentCache}
      taskNanoId={taskNanoId}
      userNanoId={userNanoId}
    />
  ) : (
    <AttachmentsListItemListView
      item={item}
      items={items}
      handleCheck={handleCheck}
      checkboxChecked={checked}
      checkable={checkable}
      modalMode={modalMode}
      onLightboxOpen={withLightbox ? handleLightboxOpen : undefined}
      cacheKeys={cacheKeys}
      updateAttachmentCache={updateAttachmentCache}
      taskNanoId={taskNanoId}
      projectNanoId={projectNanoId}
    />
  );
}

export default AttachmentsListItem;
