import {
  DeleteSelectedLifestyleCacheKeys,
  DeleteSelectedLifestyleGqlQuery,
  DeleteSelectedLifestyleGqlStatus,
  SelectedLifestyleGqlError,
  SelectedLifestyleUUID
} from '../../selectedLifestylesTypes';

import { useDeleteQuery } from '../../../common/hooks/base/reactQuery/useDeleteQuery';

interface DeleteSelectedLifestyleInput {
  uuid: SelectedLifestyleUUID;
}

interface DeleteSelectedLifestyleResponse {
  deleteSelectedLifestyle: {
    status: DeleteSelectedLifestyleGqlStatus;
    errors: DeleteSelectedLifestyleError;
  };
}

interface DeleteSelectedLifestyleError {
  fullMessages: SelectedLifestyleGqlError;
}

interface DeleteSelectedLifestyleOptions {
  cacheKeys: DeleteSelectedLifestyleCacheKeys;
  query: DeleteSelectedLifestyleGqlQuery;
}

const action = 'deleteSelectedLifestyle';

function useDeleteSelectedLifestyle({
  query,
  cacheKeys
}: DeleteSelectedLifestyleOptions) {
  const {
    deleteQueryData,
    deleteQuery,
    deleteQueryReset,
    deleteQueryError,
    deleteQueryLoading,
    deleteQueryErrorMessage
  } = useDeleteQuery<
    DeleteSelectedLifestyleInput,
    DeleteSelectedLifestyleResponse,
    DeleteSelectedLifestyleError
  >({ action, cacheKeys, query });

  return {
    deleteSelectedLifestyleData: deleteQueryData,
    deleteSelectedLifestyleError: deleteQueryError,
    deleteSelectedLifestyleLoading: deleteQueryLoading,
    deleteSelectedLifestyleErrorMessage: deleteQueryErrorMessage,
    deleteSelectedLifestyle: deleteQuery,
    deleteSelectedLifestyleReset: deleteQueryReset
  };
}

export default useDeleteSelectedLifestyle;
