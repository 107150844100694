import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';
import {
  FileAttachmentWhiteboardKeyID,
  UpdateFileAttachmentGqlQuery
} from '../../fileAttachmentsTypes';
import { UpdateItemCacheKey } from '../../../../types';

interface UpdateFileAttachmentOptions {
  query: UpdateFileAttachmentGqlQuery;
  cacheKeys: UpdateItemCacheKey[];
}

export interface UpdateFileAttachmentInput {
  clientMutationId?: string;
  uuid: string;
  threeD?: boolean;
  reference?: boolean;
  texture?: boolean;
  drawing?: boolean;
  whiteboardKeyId?: FileAttachmentWhiteboardKeyID;
}

export interface UpdateFileAttachmentError {
  fullMessages: string[] | null;
  name: string;
}

export interface UpdateFileAttachmentResponse<UpdateFileAttachmentRecordType> {
  updateFileAttachment: {
    status: string;
    errors: UpdateFileAttachmentError;
    record: UpdateFileAttachmentRecordType;
  };
}

const action = 'updateFileAttachment';

function useUpdateFileAttachment<UpdateFileAttachmentRecordType>({
  query,
  cacheKeys
}: UpdateFileAttachmentOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    UpdateFileAttachmentInput,
    UpdateFileAttachmentResponse<UpdateFileAttachmentRecordType>,
    UpdateFileAttachmentError,
    undefined
  >({
    action,
    query,
    cacheKeys
  });

  return {
    updateFileAttachmentData: updateQueryData,
    updateFileAttachmentError: updateQueryError,
    updateFileAttachmentLoading: updateQueryLoading,
    updateFileAttachmentErrorMessage: updateQueryErrorMessage,
    updateFileAttachment: updateQuery,
    updateFileAttachmentReset: updateQueryReset
  };
}

export default useUpdateFileAttachment;
